.main-login {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-box {
  position: sticky;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: auto;
  // width: 350px;
  // height: 370px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 10%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1; // Posiziona l'immagine di sfondo dietro agli altri elementi
}

.login-logo {
  height: 10vh;
  width: 10vh;
  margin-top: 20px;
  margin-bottom: 8px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.noAccount-label {
  cursor: pointer;
  font-size: 16px;
  color: white;
  border-radius: 4px;
  align-self: center;
  margin-bottom: 15px;
}

.noAccount-label:hover {
  text-decoration: underline;
  color: blue;
}
