.login-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  width: 48%; /* Adjust width to fit within the container */
}

.login-button {
  margin-top: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-title,
.signup-title {
  align-self: center;
  font-size: x-large;
}

.login-title,
.signup-title,
.login-label {
  color: black;
  text-shadow: 0.05em 0 white, 0 0.05em white, -0.05em 0 white, 0 -0.05em white,
    -0.05em -0.05em white, -0.05em 0.05em white, 0.05em -0.05em white,
    0.05em 0.05em white;
  margin-top: 0;
}

.login-label-field {
  font-size: large;
}

.login-button-label {
  font-size: small;
  color: white;
  text-shadow: 0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black,
    -0.05em -0.05em black, -0.05em 0.05em black, 0.05em -0.05em black,
    0.05em 0.05em black;
}

.login-button-label:hover {
  cursor: pointer;
}

.enderman-still,
.enderman-with-grass {
  position: absolute;
  visibility: hidden;
  bottom: 12%;
}

.enderman-still {
  left: 60%;
}

.enderman-with-grass {
  left: 55%;
}

.login-button-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.login-input {
  width: 100%;
  height: 35px;
}
