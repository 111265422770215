.navbar-main-container {
  background-color: rgb(1, 147, 74);
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-main-environment {
  color: green;
  text-align: left;
}

.login-section {
  margin-right: 10px;
  align-items: center;
}

.login-navbar-button {
  height: 30px;
  width: 70px;
}
